.header {
    height: 50vh;
    width: 100vw;
    text-align: center;
    display: table;
}

.headerContent {
    display: table-cell;
    vertical-align: middle;
}

.subHeaderMenu {
    background-color: #544F50;
    color: white;
}

.subHeaderLine {
    height: 1px;
    width: 85%;
    margin-top: 3%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 1%;
    position: relative;
}