.heroSection{
  position: relative ;
}




.heroSection::before{
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    background-image: url(../assets/images/hero-top-left.svg);
    background-size: 100% 100%;
    height: 16vw;
    top: 50;
    left: 0;
    width: 24vw;
}

.heroSection::after{
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0;
    z-index: 1;
    right: 0;
    bottom: 4px;
    border-right: 29vw solid #ED2022;
    border-top: 29vw solid transparent;
}

.heroLayer{
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.heroLayer::after{
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0;
    z-index: 10;

    right: 0;
    bottom: 0;

    border-right: 14.5vw solid #fff;
    border-top: 14.5vw  solid transparent;
}


.heroFooter{
    display: flex;
    min-height: 56px;
    position: relative;
}


.heroFooter>a {
   margin: auto 20vw auto auto;
   /* font-family: "Space Grotesk",sans-serif;  */
   font-family: 'League Spartan',sans-serif;

    color: #ED2022; 
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.4375rem;
    letter-spacing: 0.02rem;
    text-decoration: none;
 
}



 
.heroFooter>a::after {
    background-image: url(../assets/images/arrow-black.svg);
    background-size: 100% 100%;
    content: '';
    display: inline-block;
    height: 17px;
    margin: -2px 0px 0px 12px;
    position: absolute;
    top: 23px;
    vertical-align: middle;
    width: 16px;
}

.heroFooter::before{
    background-image: url(../assets/images/hero-bottom-right.svg);
    background-size: 100% 100%;
    bottom: 4px;
    height: 11vw;
    width: 17.23vw;
    z-index: 20;
    pointer-events: none;
    position: absolute;
    right: 0;
    content: '';
    display: block;
}

.heroContent{
    position: absolute;
     bottom: 6.25vw;
      left: 12.5vw;
       color: white;
       max-width: 630px;
}


 .carsImgsDIV{
    position: relative;
   max-width: 480px;
 }
 
 
 .carsImgsDIV::before{
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0;
    z-index: 1;
    left: 0;
    top: 0;
    border-bottom: 10vw solid transparent;
    border-left: 10vw solid #fff;
 }



 .carsImgsDIV::after {

    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0;
    z-index: 1;
    right: 0;
    bottom: 0;
    border-right: 10vw solid #fff;
    border-top: 10vw solid transparent;
 }


 .signupBtn{
    margin-top: 72px;
    background-image: url(../assets/images/button-pattern.svg);
    cursor: pointer;
    background-repeat: no-repeat;
    color: #000;
    text-align: right;
    background-color: transparent;
    border: 0;
    -webkit-transition: color .3s;
    transition: color .3s;
    position: relative;    
    display: block;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.4375rem;
    overflow: hidden;
    text-decoration: none;
 
   
 }

 .signupBtn::before
 {
    background: #ED2022;
    content: '';
    display: block;
    height: 100%;
    left: 69px;
    position: absolute;
    top: 0;
    -webkit-transition: left .3s;
    transition: left .3s;
    width: 100%;
    z-index: 1;
 }


 .signupBtn::after {
    border-right: 112px solid #ED2022;
    border-top: 112px solid transparent;
    content: '';
    display: block;
    height: 0;
    left: -43px;
    position: absolute;
    top: 0;
    -webkit-transition: left .3s;
    transition: left .3s;
    width: 0;
    z-index: 1;
}

 .signupBtn:hover:after {
  
    left: 10px;
}

.signupBtn:hover:before {
  
    left: 120px;
}

.signupBtn>span{
    z-index: 1;
  
    display: block;
    position: relative;
    margin-left: 69px;
    padding: 17px 60px 17px 0px;
    transition: 0.15s all ease;
 
}



.signupBtn:hover >span{
    padding: 17px 50px 17px 0px;
}


.signupBtn>span::after{
    background-image: url(../assets/images/arrow-black.svg);
    background-size: 100% 100%;
    content: '';
    display: inline-block;
    height: 17px;
    margin: -2px 0px 0px 12px;
    position: absolute;
    right: 34px;
    top: 24px;
    vertical-align: middle;
    width: 16px;
    transition: 0.15s all ease;
}

.signupBtn:hover >::after{
    right: 24px;
}


 .section3{
    margin-top: 50px;
    position: relative;
    z-index: 10;
 }


 .section3::before{

    content: '';
    display: block;
    position: absolute;

    background-image: url(../assets/images/livestream-pattern.svg);

    bottom: -2.5vw;
    height: 44.5vw;
    right: auto;
    width: 71vw;
    left: calc(50% - 179px);
    z-index: -1;
 }


 .section3::after{


    bottom: 0;
    content: '';
    display: block;
    position: absolute;
    right: 0;
    z-index: 1; 
    border-right: 10.4vw solid #fff;
    border-top: 10.4vw solid transparent;
 }


 .overlayContent{
    position: absolute;
    top:0;
    color: white;
    margin: 20px;
 }


 .ctaBtn{
  
    max-width: 572px;
    margin: 6.25vw 0px 0px 0px;

 }


 .ctaBtn > a{

    background-image: url(../assets/images/button-pattern.svg);
    background-repeat: no-repeat;
    color: #000;
    text-align: right;
    background-color: transparent;
    border: 0;
    -webkit-transition: color .3s;
    transition: color .3s;
    display: block;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.4375rem;
    overflow: hidden;
    position: relative;
    text-transform: uppercase;

 }

 .ctaBtn > a::before{
    background: #ED2022;
    content: '';
    display: block;
    height: 100%;
    left: 69px;
    position: absolute;
    top: 0;
    -webkit-transition: left .3s;
    transition: left .3s;
    width: 100%;
 }
 .ctaBtn > a::after{
    border-right: 112px solid #ED2022;
    border-top: 112px solid transparent;
    content: '';
    display: block;
    height: 0;
    left: -43px;
    position: absolute;
    top: 0;
    -webkit-transition: left .3s;
    transition: left .3s;
    width: 0;
    z-index: 1;
 }


 .ctaBtn > a:hover::before{
    left:82px
 }

 .ctaBtn > a:hover::after{
    left: -30px;
 }

 .ctaBtn > a > span{
    padding: 1vw 1vw 1vw 1vw;
    display: block;
    position: relative;
    margin-left: 69px;
   
 }

 .ctaBtn > a:hover > span{
    transform: translateX(7px);
 }


 .ctaBtn > a > span::after{
    background-image: url(../assets/images/arrow-black.svg);
    background-size: 100% 100%;
    content: '';
    display: inline-block;
    height: 17px;
    margin: -2px 0px 0px 12px;
    position: static;
    right: 24px;
    top: 24px;
    vertical-align: middle;
    width: 16px;
 }


 .slide{
 margin: 20px;
 position: relative;
 }
 .slide img{
 cursor: pointer;
 border-top-right-radius: 10px;
 border-top-left-radius: 10px;

 }

 .slide::before{
    /* content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0;
    z-index: 1;
    border-bottom: 8.75vw solid transparent;
    border-left: 8.75vw solid #fff;
    left: 0;
    top: 0;
    -webkit-transition: border .21s;
    transition: border .21s; */
 }

 .sectionSlide::before {    border-left: 8.75vw solid #53565a;}
 

 .CarouselSlider{
   height: fit-content;
     position: relative;
     margin-top: 100px;
 }

 .SliderSection{
    background: #53565a;
    overflow: hidden;
    padding: 100px 0px 100px 0px;
        /* z-index: -1 */
 }

 .SliderSecion::before{
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0;
    z-index: 1;
    border-bottom: 146px solid transparent;
    border-left: 146px solid #fff;
    left: 0;
    top: 0;
 }
 
 .SliderSection::after{
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0;
    z-index: 1;
    bottom: 0;
    right: 0;
    border-top: 123px solid transparent;
    border-right: 123px solid #fff;
 }

 .carouselPattern{
    background-image: url(../assets/images/section-pattern.svg);
    background-position: 0px 2px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    bottom: 0;
    height: 492px;
    left: 0;
    position: absolute;
    width: 826px;
    /* z-index: -1 */
    
 }

 .sliderHeaderLeft > h1{
   margin-bottom: 20px;
    font-size: 3vw;  
    line-height: 2.85vw;
    font-weight: 100;
    margin: 0px;

 }


 .sectionHeaderLeft{
    color: white;
 }


 .sliderHeaderRight>a{
    display: block;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.4375rem;
    overflow: hidden;
    position: relative;
    text-transform: uppercase;
    color: #ED2022;
    margin-top: 16px;
    text-decoration: none;
 }


 .sliderHeaderRight>a::after{
    background-image: url(../assets/images/arrow-black.svg);
    content: '';
    display: inline-block;
    height: 17px;
    margin: 0px 0px 0px 20px;
    position: absolute;
 
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    vertical-align: middle;
    width: 16px;
 }


 .slideBtn{
    cursor: pointer;
    background-color: #ED2022;
    color: #000;
    padding: 1.5vw 2vw;
    display: block;
    font-size: 1.125rem;
    line-height: 1.4375rem;
    overflow: hidden;
    position: relative;
    text-transform: uppercase;
    width: 80%;
   border-bottom-right-radius: 10px;
   border-bottom-left-radius: 10px;
 }
 .slideBtn>p{
   margin: 0;
   margin-left: 15px;
 
 }

 
 .slideBtn::before{
    /* border-bottom: 32px solid transparent;
    border-left: 32px solid #fff;
    content: '';
    display: block;
    height: 0;
    left: 0;
    position: absolute;
    top: 0;
    width: 0; */
    
 }


 .mainSlider{
    z-index: 1;
 }

 .sectionSlideBtn::before{    border-left: 32px solid #53565a; }

 .slideBtn::after{
    background-image: url(../assets/images/arrow-black.svg);
    background-size: 100% 100%;
    content: '';
    display: inline-block;
    height: 17px;
    margin: 0px;
    position: absolute;
    right: 24px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    vertical-align: middle;
    width: 16px
 }


 .sliderArrows{
    margin-left: calc(75% - 40px);
    margin-top: 20px;
    z-index: 1;
 }


 .sliderArrows>button{
    background-color: transparent;
    border: 0;
    cursor: pointer;
    z-index: 1;
 }


 .downloadAppHeader>h2{
   margin: 0px;
 }





 @media screen and (min-width :600px) {
   .heroSection{
      padding-top: 50px ; position: relative ;
  }

}

@media screen and (max-width :600px) {
   .slideBtn>p{
      font-size: 12px;
       }
      

}


