body {
    background-color: #F9F5F0;
}

.header {
    position: relative;
    text-align: center;
    color: white;
    margin: 2px;
    margin-top: 4rem;

}

.header img {
    object-fit: cover;
}

.headerCentered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: black;
    width: 100%;
    height: 100%;
    opacity: 0.5; */
}

.headerCenteredName {
    font-size: 5vw;
    text-transform: uppercase;
    /* font-family: 'Signika', sans-serif; */
    font-family: 'League Spartan',sans-serif;

    font-weight: 600;
}

.headerCenteredYear {
    font-size: 4vw;
}

.title {
    font-size: 40px;
    font-weight: 900;
    /* font-family: 'Signika', sans-serif; */
    font-family: 'League Spartan',sans-serif;


}

.subtitle {
    font-size: 22px;
}

.monthBox {
    background-color: black;
    font-size: 20px;
    padding: 5px;
    border-radius: 15px;
    text-transform: uppercase;
}

.container {
    width: 70%;
    height: 100%;
    margin-top: 1rem;
}

.tabPannels {
    background-color: red;
}

.listDiv {
    border-bottom: 8px solid red;
    border-top: 8px solid red;
    border-right: 8px solid red;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    margin-top: 50px;
    height: 600px;
}
.listDivTitle{
    font-size: 15px;
    max-width:90px;
    border-bottom: 3px solid red;
    margin-left: 15px;
}
.description {
    margin-top: 50px;
    margin-bottom: 70px;
}
.circuitDescription{
    margin-top: 50px;
    margin-bottom: 70px;
    border-bottom: 1px solid #A6ACB2;
    border-top: 1px solid #A6ACB2;
    border-right: 1px solid #A6ACB2;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}
.circuitDescription div{
    padding: 10px;
    margin-top:20px;
    margin-bottom   :20px;
}
.rightSideMap {
    margin-left: 10px;
    border: 1px solid #A6ACB2;
    border-radius: 20px;
    height: 300px;

}

.rightSideMap div {
    margin-left: 10px;
    margin-right: 10px;
}

.rightSideMap span {
    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    color: red;
}

.rightSideMap p {
    font-size: 16px;
    font-weight: 900;
    padding: 5px;
    border-bottom: 1px solid #A6ACB2;
}

.rightSideMap p:hover {
    border-bottom: 1px solid red;
    cursor: pointer;
    color:red;
}

.rightSideMapImg {
    text-align: center;
}

.rightSideAds {
    text-align: center;
    margin-top: 20px;
}

.footer {
    width: 100%;
    margin: auto;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    background: #111;
    height: 300px;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-align: center;
}

.footerContent {
    width: 90%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-align: center;
}

.footerVideo {
    height: 90%;
    margin: auto;
    width: 50%;

}

.footerVideo video {
    width: 100%;
    height: 100%;

}
.circuitTitle{
    font-size: 25px;
}

.circuitListDiv{
    border-top: 8px solid red;
    border-right: 8px solid red;
    border-top-right-radius: 25px;
    margin-top: 50px;
    height: 100%;
}
.circuitListDivRightGrid{
    height: 100px;
}
.circuitListDivRight{
    height: 70px;
    border-right:2px solid #A6ACB2;
    border-bottom:2px solid #A6ACB2;
    border-bottom-right-radius: 12px;
}
.circuitListDivRight p{
    font-size: 12px;

}

.circuitListDivRight span{
    font-size: 45px;
    font-weight: 900;
    line-height: 1px;
}

.circuitRIghtAds{
    margin: 60px;
}

@media only screen and (max-width: 600px) {
    .container {
        width: 95%;
        margin-top: 80px;
    }

    .title {
        font-size: 30px;

    }

    .subtitle {
        font-size: 18px;

    }
}

@media only screen and (max-width: 1500px) {
    .circuitListDivRight p{
        font-size: 10px;
    }
    
    .circuitListDivRight span{
        font-size: 25px;
    }
}