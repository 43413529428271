.header {
    height: 50vh;
    width: 100vw;
    text-align: center;
    display: table;
}

.headerContent {
    display: table-cell;
    vertical-align: middle;
}

.subHeaderMenu {
    background-color: #544F50;
    color: white;
    width: 100%;
}

.subHeaderLine {
    height: 1px;
    width: 85%;
    margin-top: 3%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 1%;
    position: relative;
}

.filter {
    width: 200px;
    height: 25px;
    position: absolute;
    margin-top: -25px;
    right: 0%;
}

.CardsSection {
    display: flex;
    margin-top: 3%;
    margin-right: 5%;
    margin-left: 5%;
    margin-bottom: 5%;
    width: 100%;
}

.headerPart1 {
    width: 100%;
    height: 100px;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

.headerPart1Content {
    padding-top: 7%;

}

.headerPart2 {
    background-color: #544F50;
    height: 100px;
    text-align: center;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 30px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.headerPart2Content {
    width: 50%;
    height: 100%;
    margin: auto;
    padding-top: 7%;
}

.headerPart3 img {
    padding: 12px;
}

.headerPart3 {
    height: 100px;
    background-color: #544F50;
    text-align: right;
}

.link {
    width: 100%;

    text-decoration: none;
}

.viewsPar {
    height: 60px;
    width: 40vw;
    border-bottom: 2px solid black;
    margin-left: 30%;
    position: relative;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;
}

.viewsPar p {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 22px;
    color: #BF2A30;
}

.loadingBtn {
    height: 60px;
    width: 40vw;
    margin-left: 30%;
    position: relative;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;
    background: #FFFFFF;
    color: #544F50;
    border: 1px solid #BF2A30;
}

.loadingBtnContainer p {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 22px;
    text-transform: uppercase;
}

.loadingBtn:hover {
    cursor: pointer;
    background-color: #BF2A30;
    color: #FFFFFF;
}


@media only screen and (max-width: 1500px) {
    .filter {
        right: 20%;
    }
}


@media only screen and (max-width: 1100px) {
    .filter {
        margin-top: 1px;
        right: 20%;
    }
    .CardsSection{
        margin-top: 100px;
    }
    .headerPart1 {
        font-size: 28px;
    }

    .headerPart2 {
        font-size: 26px;
    }

    .headerPart3 img {
        width: 80%;
        padding: 10px;
    }

    .imageSection img {
        width: 400px;
    }
}


@media only screen and (max-width: 900px) {
    .viewsPar {
        width: 40vh;
        margin-left: 13%;
        font-size: 18px;
    }
    .filter {
        margin-top: 10px;
        right:40%;
    }
    .CardsSection{
        margin-top: 100px;
        width: 90%;
    }
}