.articleCard{
    max-width: 266px;
    background: white;
    text-align: center;
}

.articleCard >p{
    font-family: 'League Spartan';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 22px;
text-align: center;

color: #000000;
padding: 20px;
}