/* Slideshow */

.slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 100%;
    max-height: 80%;
  }
  .slideshowSlider {
    white-space: nowrap;
    /* transition: ease 1000ms; */
  }
  
  .slide {
    display: inline-block;
    height: 400px;
    width: 100%;
    border-radius: 40px;
  }

  @media only screen and (max-width: 600px) {
    .slideshow img{
      height:50vh;
    }
}
 