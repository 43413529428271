.main{
    background-color: rgb(240, 240, 240);
}

.heroSection{
    height: 100vh;  
    width: 100vw;
    text-align: center;
    /* padding-top: 100px; */
    color: white;
    overflow: hidden;
    background: #C04848;  /* fallback for old browsers */
    /* background: linear-gradient(269.86deg, rgba(119, 75, 77, 0.4) 16.35%, rgba(122, 65, 67, 0.4) 90.92%), url("../../assets/images/sharewithus.png");  /* Chrome 10-25, Safari 5.1-6 */
    /* background: linear-gradient(269.86deg, rgba(119, 75, 77, 0.4) 16.35%, rgba(122, 65, 67, 0.4) 90.92%), url("../../assets/images/sharewithus.png"); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ 
    background-size: cover !important;
    background-repeat: no-repeat !important;

    justify-content: center;
    align-items: center;
    align-content: center;
    display: flex;
    flex-direction: column;

}



.heroSection>div{
padding: 30px;
}

.heroSection>div>h1{
    /* font-family: 'Avenir Next LT Pro'; */
font-style: normal;
font-weight: 700;
font-size: calc(3vw + 12px);
line-height: 58px;
/* identical to box height */

text-transform: uppercase;

color: #FFFFFF;
}


.heroSection>div>h2{
 
 
    /* font-family: 'Avenir Next LT Pro'; */
font-style: normal;
font-weight: 700;
font-size: calc(1vw + 12px);
line-height: 29px;
text-align: center;
text-transform: uppercase;

color: #FFFFFF;
}
input:focus::placeholder {
    color: transparent;
}
.inputs >input:focus::placeholder {
    color: transparent;
}
.topCard{
    width: 100%;
    max-width: 611px;
    height: 100%;
    background-color: white;
    margin: auto;
}

.header >h1{
    font-style: normal;
font-weight: 700;
font-size: 32px;
line-height: 38px;

color: #FFFFFF;
justify-content: center;
justify-items: center;
align-content: center;
align-items: center;
display: flex;
 


}


.topCard>.header >h1:after {
    content: url('../../assets/images/ticket.png');
    left: 10px;
    position: relative;
}


.body > h2{
    font-family: 'League Spartan';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 18px;
text-align: center;
color: #000000;
}


.topCard{
    margin-top: -200px;
}

.topCard > .body{
    text-align: center;

}


.attachment{
    margin: auto;
}

.attachment>input{
    display: none;
}

.inputs{
    display: flex;
    justify-content: space-around;
    margin-top: 25px;
    padding-bottom: 25px;
}

.inputs > input{
    max-width: 152px;
    height: 24px;
    background: #D9D9D9;
    border: 0px;
    text-align: center;
}

.inputs > input::placeholder {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
    /* identical to box height */
    
    text-align: center;
    
    color: #000000;
}

.title{
    font-family: 'League Spartan';
font-style: normal;
font-weight: 700;
font-size: 42px;
line-height: 29px;
text-align: center;
color: #BF2A30;
}

.card{
    width: 100%;
    max-width: 400px;
    margin: auto;
    height: 100%;
    background-color: white;
}

 
.header{
    background-color:#BF2A30;
    text-align: center;
    padding: 20px;
    color: #FFFFFF;
}
.header div{
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    /* margin-right: 100px; */
}
.body{
    margin: 5%;
}
.body p{
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;
    color: #BF2A30;
}
.body span{
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #9F9F9F;
}
.withBorder{
    border-bottom: 1px solid #D8D4D4;
}


.withBorder>input, .withBorder>div>input, .withBorder > select{
 

 width: 100%;
height: 45px;
background: #D9D9D9;
border: 0px;
text-align: center;
}
.billingTitle{
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 30px;
    margin-left: 30px;
    text-transform: uppercase;
    color: #BF2A30;
}
 

.bottom{
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    align-items: end;
    padding-bottom: 20px;
}
 


.bookButton{
 
    background-color:#544F50 !important;
    text-transform:  uppercase ;
       /* marginTop: "58px", */
    height:  50px ;
    width:  150px ;
  }







  .latest {
    margin-top: 50px;
    /* width: 100%; */
    margin-left: 5%;
    margin-right: 5%;
}

.latestContent {
    margin-top: 200px;
}

.latestContentTitle {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 33px;
    text-transform: uppercase;
    color: #BF2A30;
}

.subHeaderLine {
    height: 1px;
    width: 100%;
    background-color: black;
    border: 1px solid black;
    position: relative;
}

.subHeaderLineContent {
    background-color: #BF2A30;
    width: 15vh;
    height: 25px;
    position: absolute;
    margin-top: -12px;
    right: 10%;
}
.subHeaderLineContentAr {
    background-color: #BF2A30;
    width: 15vh;
    height: 25px;
    position: absolute;
    margin-top: -12px;
    left: 10%;
}
 

 .cards{
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
 }

 .cards > .card{


 
    max-width: 300px;
 

 }

 .cards > .card > .header > h1{
    font-family: 'League Spartan';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 18px;
text-align: center;
text-transform: uppercase;

color: #FFFFFF;
 }

 .cards > .card > .body > h2{
    max-width: 138px;
        margin: auto;
        margin-bottom: 20px;
        margin-top: 20px;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
    
    color: #000000;


 }



 @media only screen and (max-width: 1000px) {
    .cards{
        flex-direction: column;
    }

    .cards > .card{
        margin-bottom: 50px;
    }
 }




  @media only screen and (max-width: 600px) {
    .inputs{
        flex-direction: column;
        align-items: center;
    }
    .inputs > input{
        margin-bottom: 20px;
    }
    .latest {
        width: 90%;
        margin-right: 0%;
    }
    /* .heroSection{
        height: 400px;
    } */
 }

.latestBottom{
    position: relative;
}

 .latestBottom>h1{
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    
    color: #BF2A30;
 }


 .seperator{
    /* position: absolute; */
    max-width: 500px;
 margin: auto;

border: 1px solid #000000;
 
 }

  .loadMore{
display: flex;
justify-content: center;
  }

 .loadMore> button{
    background: #FFFFFF;
border: 1px solid #BF2A30;

font-family: 'League Spartan';
font-style: normal;
font-weight: 400;
font-size: 32px;
line-height: 29px;

color: #544F50;
padding: 20px 60px 20px 60px;
 width:500px;
margin : 20px 0px 20px 0px;

cursor: pointer;
 }


 .loadMore> button:hover{
    background: #d7d7d7;
 }
