body {
    background-color: #F9F5F0;
}

.container {
    background-color: white;
    width: 80%;
    height: 100%;
    margin-top: 5rem;
}

.tableRight {
    /* style={{ paddingLeft: '40px', paddingTop: '20px', width: "100%", borderSpacing: '2px' }} */
    padding-left: 40px;
    padding-top: 40px;
    width: 100%;
    border-spacing: 2px;
}

.leftTH {
    /* font-family: 'Courier New', monospace; */
    font-family: 'League Spartan',sans-serif;

    font-size: 23px;

}

.rightTH {
    font-weight: normal;
}

.subImage {
    margin-top: 2rem;
    margin-left: 3rem;
}

.subImage span {
    font-size: 40px;
    /* font-family: 'Courier New', monospace; */
    font-family: 'League Spartan',sans-serif;

    margin-right: 10px;
}

.subImage p {
    font-size: 25px;
    font-weight: bold;
    /* font-family: 'Times New Roman', serif; */
    font-family: 'League Spartan',sans-serif;

}

.containerPart2 {
    margin-left: 45px;
    margin-right: 45px;
    margin-bottom: 50px;
    margin-top: 50px;

}

.containerPart2 h2 {
    /* font-family: 'Courier New', monospace; */
    font-family: 'League Spartan',sans-serif;

    font-size: 40px;
    color: #E74C3C;
    height: 35px;
    word-wrap: break-word;
    text-align: justify;
}

.containerPart2 div {
    /* font-family: "Titillium Web", Helvetica, sans-serif; */
    font-family: 'League Spartan',sans-serif;

    line-height: 1.8;
    font-weight: 100;
}

.driversPart:hover {
    cursor: pointer;
    transform: scale(1.02);
    color:white;
    background-color: black;
}

@media only screen and (max-width: 600px) {
    .container {
        width: 95%;
        margin-top: 80px;
    }

    .leftTH {
        font-size: 16px;
    }

    .rightTH {
        font-size: 13px;
    }

    .containerPart2 h2 {
        font-size: 30px;
    }
    .showPart{
        height: 200px;
    }

}