.div {
    max-width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.p {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;

    color: #000000;
}


.btn {
    background: #231F20;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 12px;
    text-align: center;
    border: none;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 10px;
    border-radius: 20px;
    margin: 10px;
    width:150px;
}

.newsWithBorder {
    border-right: 1px solid #BF2A30;
}

.img {
    height: 200px;
}


@media only screen and (max-width: 1000px) {

    .imageSection {
        justify-content: center;
        margin-top: 10px;
    }

    .newsWithBorder {
        border-right: none;
    }
}