.header {
    height: 50vh;
    width: 100vw;
    text-align: center;
    display: table;
}

.headerContent {
    display: table-cell;
    vertical-align: middle;
}

.subHeaderMenu {
    background-color: #544F50;
    color: white;
}

.title{
    text-align: center;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #BF2A30;
    text-transform: uppercase;
}
.subTitle{
    text-align: center;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
}

.cardSection {
    display: flex;
    margin-top: 10%;
    margin-right: 5%;
    margin-left: 8%;
    margin-bottom: 8%;
    width: 100%;
}
.detailsCardHead{
    background-color: #544F50;
    height: 10vh;
}
.detailsCardHeadContent{
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 30px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding-left:5%;
    padding-top:7%;
}
.detailsCardBodyLeft{
    margin-top: 30px;
    width: 100%;
    display: flex;
}
.detailsCardBodyLeft1{
    width: 50%;
    height: 80px;
    flex: 40%;
    border-right: 1px solid red;
}
.detailsCardBodyLeft1_content{
    height: 28px;
    width: 83px;
    background-color: #F0F0F0;
    margin-left: auto;
    margin-right: 0;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    text-transform: uppercase;
    color: #BF2A30;
    text-align: center;
}
.detailsCardBodyLeft2{
    width: 50%;
    flex: 60%;
}
.detailsCardBodyLeft2_content lu{
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 30px;
    text-transform: uppercase;
    color: #000000;
}

.detailsCardBodyLeft2_content div{
    font-family: 'League Spartan';
    font-style: normal;
    font-size: 14px;
    line-height: 30px;
    text-transform: uppercase;
    color: #000000;
    margin-left:50px;
}

.detailsCardBodyRight{
    width:60%;
    margin-left:20px;
}

.detailsCardBodyRight1{
    border-bottom: 2px solid #BF2A30;
    height: 50px;
}
.detailsCardBodyRight1_content{
    padding-top: 20px;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;

}
.detailsCardBodyRight2{
    border-bottom: 2px solid #BF2A30;
    height: 50px;
    margin-top: 50px;
}
.detailsCardBodyRight2_content{
    padding-top: 20px;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
}
.circuitBody{
    width: 80%;
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 10%;

}
.circuitBodyPart1right{
    width: 100%;
    height: 542px;
    border: 2px solid #BF2A30;

}
.circuitBodyPart1right table{
    width: 100%;

}
.circuitBodyPart1right th{
    height: 30px;
    background: #BF2A30;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    border-right: 1px solid #BF2A30;

}
.circuitBodyPart1right td{
    height: 100px;
    /* border-right: 1px solid #BF2A30; */
}
.circuitBodyPart1right p{
    margin-left: 30px;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #000000;
}
.lap_record{
    margin-left: 30px;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #000000;
    float: left;
}
.smallP{
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 105px;
    margin-left: 20px;
    color: #000000;
}
.descriptionBody{
    width: 80%;
    min-height:30vh;
    /* border: 1px solid black; */
    margin-bottom: 5%;
    margin-left: 10%;
}
.descriptionBodyHeader{
    text-align: center;
    background: #544F50;
    height: 60px;
}
.descriptionBodyHeader p{
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 22px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding-top: 20px;
}
.descriptionBodyContent{
    background: #FFFFFF;
    min-height:30vh;

}
.descriptionBodyContentBody{
    padding: 5%;
}
@media only screen and (max-width: 1300px) {

    .cardSection{
        margin-left: 20%;
        margin-top: 20%;
    }
    .descriptionBodyHeader p{
        font-family: 'League Spartan';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        color: #FFFFFF;
        padding-top: 20px;
    }
}
