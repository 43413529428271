@import url(//db.onlinewebfonts.com/c/19a516eaa6ae19744976e313ea5896f4?family=29LT+Bukra+Variable);

@font-face {
  font-family: "29LT Bukra Variable";
  src: url("//db.onlinewebfonts.com/t/19a516eaa6ae19744976e313ea5896f4.eot");
  src: url("//db.onlinewebfonts.com/t/19a516eaa6ae19744976e313ea5896f4.eot?#iefix") format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/19a516eaa6ae19744976e313ea5896f4.woff2") format("woff2"),
    url("//db.onlinewebfonts.com/t/19a516eaa6ae19744976e313ea5896f4.woff") format("woff"),
    url("//db.onlinewebfonts.com/t/19a516eaa6ae19744976e313ea5896f4.ttf") format("truetype"),
    url("//db.onlinewebfonts.com/t/19a516eaa6ae19744976e313ea5896f4.svg#29LT Bukra Variable") format("svg");
}


.headerNav {
  /* position: absolute;
    width:  80% ;
background-color: rgba(40,40,40,0.7);
height: 50px;
z-index: 1; */
  text-transform: uppercase;
}

.headerNavArabic {
  /* position: absolute;
  width:  80% ;
background-color: rgba(40,40,40,0.7);
height: 50px;
z-index: 1; */
  font-family: '29LT Bukra Variable';
}

.headerNav::after {
  /* position:absolute; */
  /* border-color: rgba(40,40,40,0.7) transparent transparent transparent; */
  /* border-style: solid; */
  /* border-width: 50px 60px 0 0; */
  /* content: '';
    height: 0;
    width: 0;
    right: -60px;
    top: 0; */
}



.header {
  position: absolute;
  width: 80%;

  height: 50px;
  z-index: 1;



  /* position: fixed;
top: 0;
right: 0;
left: 0;
z-index: 9999; */
}

.menuBtn {


  text-decoration: none;

  color: white;
  width: 100%;
  z-index: 1;
  /* color: white; */
  padding: 14px 16px;

  /* font-size: 17px; */
  display: block;
}



.menuLinks {
  display: grid;
  padding: 10px 100px 20px 100px;
  transition: all 2s;
  text-transform: uppercase;
}


.hidenMenuLinks {
  display: none;
  transition: all 2s;
}


.navMenu {
  overflow: hidden;
  background-color: #333;
  position: relative;

}

.navMenu #myLinks {
  display: none;
}



.menuIcon {
  font-size: 32px;
  padding: 10px, ;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}

.menuBtn:hover {

  color: #ED2022;
}

.active {
  background-color: #04AA6D;
  color: white;
}




.nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  /* height: 50px; */
  /* margin-left: 20%; */
  margin-bottom: 20px;
  margin-top: 20px;
  z-index: 1;

}

.nav a {
  z-index: 1;
  text-decoration: none;
}

.navItem {
  color: #544F50;
  margin: 10px;


  cursor: pointer;
  min-width: 70px;
  text-align: center;



  font-family: 'League Spartan', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;


}

.navItem:hover {
  opacity: .7;
}



.logo {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.logo2 {
  position: absolute;
  top: 10;
  right: 0;
  z-index: 1;
}



/* 
.underline{
  
    border-bottom: 2px solid white;
    text-decoration:unset !important;
    transition: all 0.2s;
}

  */












.link,
.active_link {
  /* font-family: sans-serif; */
  font-family: 'League Spartan', sans-serif;

  text-decoration: none;
  color: #544F50;
  position: relative;
  /* margin: 10px 0; */
  display: inline-block;
  padding: 12px 10px;
}


.active_link>h1 {
  font-family: 'League Spartan';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;
  text-decoration-line: underline;

  color: #BF2A30
}

.link::after,
.active_link::after {
  content: "";
  background: white;
  height: 2px;
  position: absolute;
  bottom: 0;
  transition: .16s all 0.025s;
}

.link::after,
.active_link::after {
  left: 100%;
  right: 0;

}

.active_link~.link::after {
  left: 0;
  right: 100%;


}

.active_link::after {
  left: 0;
  right: 0;
}

.shoppingHeader {
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 20px;
  height: 35px;
  background-color: #ED2022;

}

.shoppingHeader>svg {
  cursor: pointer;
}

.shoppingHeader>p {
  margin-left: 10px;
  color: white
}

.drawer {
  width: 30vw;
  padding-right: 20px;
  padding-left: 20px;
}


.drawerHeader,
.drawerFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #000;
}

.drawerHeader>h4 {
  cursor: pointer;
}


.drawerBody {
  border-bottom: 2px solid #000;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.checkout {
  width: 100%;
  background-color: #ED2022;
  color: #fff;
  border: 0;
  display: inline-block;
  text-align: center;
  padding: 1rem 2rem;
  transition: color .3s, background-color .3s, opacity .3s;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.checkout:hover {
  background-color: #8d1416;
}

.remove {
  cursor: pointer;
  border: 0px;
  background-color: transparent;
  color: grey;

  align-items: center;

  display: flex;
}

.languagePart {
  /* border-right: 2px solid white;
  border-left: 2px solid white; */
  padding: 10px;
  text-align: center;
}



@media screen and (max-width :600px) {

  .drawer {
    width: 80vw;

  }

}

@media only screen and (min-width: 1000px) and (max-width: 1500px) {
  .navItem {
    margin: 2px;
    min-width: 20px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 990px) {
  .navItem {
    margin: 2px;
    min-width: 20px;
    font-size: 12px;
  }
}
