.carouselItem {

   position: relative;
 
   width: 100%;

}

.carouselItem>a>img {
   width: 100vw;
   max-height: 60vh;

   z-index: -1;
}

.carouselText {
   z-index: 1;
   position: absolute;
   bottom: 20vh;

   left: 10vw;
}

.slide {

   width: 20vw !important;

   border-bottom: 2px solid #000;
}

.firstSlide {}

.sliderContainer {
   position: relative;
   margin-top: 20px;
   margin-bottom: 100px;
}

.slider {
   margin-left: 9vh;
}


.sliderArrowLeft {


   left: 0vw;


}

.sliderArrowRight {

   right: 0vw;


}

.sliderArrows>button {
   /* position: relative; */
   outline: 0;
   cursor: pointer;
   background-color: transparent;
   color: #fff;
   touch-action: manipulation;
   position: absolute;
   z-index: 1;
   top: 40%;
   transform: translateY(-50%);
   border: 0;
   width: 4vw;
   height: 4vw;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 0;
   transition: opacity .3s;
}

.sliderArrows>button::before {
   content: "";
   width: 100%;
   height: 100%;
   position: absolute;
   top: 0;
   left: 0;
   border-radius: 50%;
   background-color: rgba(0, 0, 0, .5);
   z-index: -1;
   transition: background-color .3s;
}


.sliderArrows>button:hover:before {
   background-color: #ED2022;
}


.slideSection {
   position: relative;
}

.slideSection::before {
   content: "";
   background-color: rgba(0, 0, 0, .3);
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 4;

   position: absolute;
   opacity: 0;
   transition: opacity .3s;


}

.slideSection:hover::before {
   opacity: 1;
}


.slideBtn {
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
   z-index: 6;
   position: absolute;
   opacity: 0;
   transition: opacity .3s;
}


.slideBtn>button {
   background-color: #ED2022;
   color: #fff;
   border: 0;
   display: inline-block;
   text-align: center;
   padding: 1rem 2rem;
   transition: color .3s, background-color .3s, opacity .3s;
   text-transform: uppercase;
   cursor: pointer;
   white-space: nowrap;
   font-size: 1rem;
   font-weight: 700;
}



.slideSection:hover .slideBtn {
   opacity: 1;
}


.slideBtn>button:hover {
   background-color: #881719;
}


.actions {
   display: flex;
   justify-content: space-between;
   height: 50px;
}

.counter {
   display: flex;
   height: 100%;
   width: 30%;
   border: 1px solid #ED2022;
}

.add {
   height: 100%;
   width: 60%;
}

.counter>button {
   width: calc(100%/3);
   background-color: #ED2022;
   border: 0px;
   color: white;
   font-size: 1.4rem;
   font-weight: 700;
   cursor: pointer;

}

.counter>p {
   width: calc(100%/3);
   text-align: center;
}

.add>button {
   width: 100%;
   height: 100%;
   background-color: #ED2022;
   border: 1px solid #ED2022;
   color: white;
   font-size: 1.4rem;
   font-weight: 700;
   cursor: pointer;
}


.counter>button:hover,
.add>button:hover {
   background-color: #ab181b;
}


.sliderHeader {
   font-weight: 700;
   /* font-family: Space Grotesk, "sans-serif"; */
   font-family: 'League Spartan',sans-serif;

   font-size: 3.5vw;
   padding: 20px;
}


.priceList {
   display: flex;
   /* justify-content: center; */
   align-items: center;
}


.priceList>h2 {
   margin-right: 20px;
}

.priceList>h3 {
   text-decoration: line-through;
}


.sizeLabel {
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   width: 3rem;
   height: 3rem;
}

.sizeLabel>input {
   position: absolute;
   left: 0;
   top: 0;
   z-index: 2;
   opacity: 0;
   visibility: hidden;
}

.checkedSize {
   background-color: #ED2022;
   border-color: #ED2022;
   color: #fff;



   display: block;
   border-radius: 50%;
   position: relative;
   z-index: 1;
   overflow: hidden;
   text-transform: uppercase;
   font-weight: 700;
   font-size: 1.4rem;
   line-height: 3rem;
   text-align: center;
   width: 100%;
   height: 100%;

}

.uncheckedSize {
   box-shadow: inset 0 0 0 1px #adadad;
   background-color: #fff;
   color: #000;

   display: block;
   border-radius: 50%;
   position: relative;
   z-index: 1;
   overflow: hidden;
   text-transform: uppercase;
   font-weight: 700;
   font-size: 1.4rem;
   line-height: 3rem;
   text-align: center;
   width: 100%;
   height: 100%;

}

.sizesList,
.sizesList>ul {
   display: flex;
   align-items: center;
}

.colorsList,
.colorsList>ul {
   display: flex;
   align-items: center;
}



.size_li {
   margin: 5px;
}







.color_li {
   margin: 5px;
}

.coloredUnSelectedDiv {

   border: 3px solid #dfdfdf;
   height: 3.5rem;
   width: 3.5rem;
   position: relative;
   overflow: hidden;
   padding: 1px;
   border: 3px solid #dfdfdf;
   cursor: pointer;
   border-radius: 50%;
}

.coloredSelectedDiv {
   border: 3px solid #ED2022 !important;

   height: 3.5rem;
   width: 3.5rem;
   position: relative;
   overflow: hidden;
   padding: 1px;
   border: 3px solid #dfdfdf;
   cursor: pointer;
   border-radius: 50%;
}



.color1Div {
   opacity: 1;
   height: 100%;
   width: 100%;
   border-radius: 50%;
   overflow: hidden;
   position: relative;
   transition: opacity .3s;
   /* background: #FF8000!important; */

}

.color2Div {

   position: absolute;
   top: -0.5px;
   left: calc(50% - 0.5px);
   transform-origin: 0 50%;
   transform: rotate(45deg);
   height: 100%;
   width: 100%;
   /* background: #000000!important; */

}


.priceFooter {
   display: flex;
   align-items: center;

}

.priceFooter>h5 {
   text-decoration: line-through;
   margin: 0px 10px 0px 0px;

}



@media only screen and (min-width: 810px) {

   .detailPart {
      margin-top: 10%;
   }
}

@media only screen and (max-width: 800px) {

   .slidePart img {
      width: 90%;
      height: 350px;
   }

   /* .detailPart{
      height: 100px;
   } */
   .detailPart h1 {
      font-size: 20px;
      line-height: 1.5;
   }

   .priceList {
      font-size: 12px;
   }

   .priceList h2,
   h3 {
      font-size: 20px;
      /* line-height: 0.2; */
   }

   .sizesList {
      font-size: 10px;
   }

   .sizesList h3 {
      font-size: 18px;
   }


}




@media screen and (min-width :600px) {
   .carouselItem {

     
      margin-top: 50px;
 
   
   }






}

@media screen and (max-width :600px) {
   .detailPart, .sizesList, .colorsList,.priceList{
      margin: 20px;
      justify-content: center;
      align-items: center;
      text-align: center;
      align-content: center;
   }

   .sizesList{
      display: block;
   }


   .slide {

      width: 50vw !important;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
    
   }
}



