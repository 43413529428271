.card{
    width: 100%;
    height: 100%;
    background-color: white;
}
.header{
    background-color:#BF2A30;
    text-align: center;
    padding: 20px;
    color: #FFFFFF;
}
.header div{
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    margin-right: 100px;
}
.body{
    margin: 5%;
}
.body p{
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;
    color: #BF2A30;
}
.body span{
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #9F9F9F;
}
.withBorder{
    border-bottom: 1px solid #D8D4D4;
}
.billingTitle{
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 30px;
    margin-left: 30px;
    text-transform: uppercase;
    color: #BF2A30;
}
.orderBtn{
    background-color: #544F50 !important;
    text-transform: uppercase !important;
    margin-top:20px !important;
    height: 60px !important;
    width: 200px !important;
    color: white !important;
    border-color: #544F50 !important;
    margin-left: 40% !important;
    margin-bottom: 10px !important;
}
@media only screen and (max-width: 800px) {
    .orderBtn{
        margin-left: 30%;
        background-color: #544F50 !important;
        text-transform: uppercase;
        margin-top:20px;
        height: 60px;
        width: 200px;
        color: white;
        border-color: #544F50;
        /* margin-left: 40%; */
        margin-bottom: 10px;
    }
    
    .header div{
        width: 100%;
        text-align: left;  
    }
    
}