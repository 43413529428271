.body {
    width: 100%;
}
.body2 {
    width: 100%;
    margin-top: 50px;
}

.body p {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 10px;
    text-transform: uppercase;
    color: #BF2A30;
}

.body div {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 10px;
    text-transform: uppercase;
}

.body2 p {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 10px;
    text-transform: uppercase;
    color: #BF2A30;
}

.body2 div {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 10px;
    text-transform: uppercase;
}