.header {
    height: 50vh;
    width: 100vw;
    text-align: center;
    display: table;
}

.headerContent {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    background: linear-gradient(270.19deg, rgba(160, 78, 78, 0.62) 0.13%, rgba(63, 63, 63, 0.35) 98.06%);
}

.headerContent div {
    position: absolute;
    left: 35%;
    width: 30%;
}

.header h3 {
    font-weight: 700;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 3vw;
    margin-top: -20px;
    color: white;
    text-transform: uppercase;
}

.subHeader {
    position: absolute;
    top: 55%;
    left: 15%;
    background: white;
    /* height: 280px; */
    width: 70%;
}

.subHeaderContent {
    width: 90%;
    margin-top: 10px;
    margin-left: 180px;
    height: 100%;
    margin-bottom: 50px;
}

.subHeaderContent p {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 60px;
    text-transform: uppercase;
    color: #BF2A30;
}

.subHeaderContentBtn {
    margin-top: 95px;
}

.body {
    margin-top: 50px;
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
}

.bodyContent {
    margin-top: 200px;
}

.bodyContentTitle {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 33px;
    text-transform: uppercase;
    color: #BF2A30;
}

.subHeaderLine {
    height: 1px;
    width: 100%;
    background-color: black;
    border: 1px solid black;
    position: relative;
}

.subHeaderLineContent {
    background-color: #BF2A30;
    width: 15vh;
    height: 25px;
    position: absolute;
    margin-top: -12px;
    right: 10%;
}

.cardSection {
    margin-top: 50px;
    width: 100%;
    margin-left: 120px;
    margin-right: 120px;
    margin-bottom: 50px;
}

@media only screen and (max-width: 800px) {
    .cardSection {
        margin-top: 20px;
        margin-left: 50px;
        margin-right: 50px;
    }

    .header {
        height: 400px;
    }

    .headerContent div {
        width: 40%;
        position: absolute;
        left: 35%;
    }

    .header h3 {
        width: 100%;
        font-size: 25px;
    }
    .subHeader{
        top:45%;
    }
    .subHeaderContent{
        margin-left : 30px;
    }
    .body{
        margin-top: 500px;
    }

}

@media only screen and (max-width: 1100px) and (min-width:801px) {
    .body{
        margin-top: 200px;
    }

    .subHeaderContent{
        margin-left :30px;
    }
}


@media only screen and (max-width: 1500px) and (min-width:1101px) {

    .subHeaderContent{
        margin-left :50px;
    }
}
