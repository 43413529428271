.header {
    height: 50vh;
    width: 100vw;
    text-align: center;
    display: table;
}

.headerContent {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
}
.headerContent div {
    width: 100%;
    border: 1px solid white;
    position: absolute;
    left:35%;
    width: 30%;
}
.header h3 {
    font-weight: 700;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 3vw;
    line-height: 0px;
    color: white;
    text-transform: uppercase;
}

.subHeaderFilter {
    /* height: 1px; */
    width: 100vw;
    margin-top: 1%;
    margin-left: 5%;
    margin-right: 5%;
    /* margin-bottom: 0px; */
    position: relative;
}

.filter {
    width: 10%;
    border: 1px solid red;
    position: absolute;
    margin-bottom: 25px;
    right: 0%;
}

.subHeaderLine {
    height: 1px;
    width: 100vw;
    background-color: black;
    border: 1px solid black;
    margin: 5%;
    position: relative;
}

.subHeaderLineContent {
    background-color: #BF2A30;
    width: 15vh;
    height: 25px;
    position: absolute;
    margin-top: -12px;
    right: 10%;
}
.CardsSection{
    display: flex;
    margin-top: 3%;
    margin-right: 5%;
    margin-left: 5%;
    margin-bottom: 5%;
    width: 100%;
}

@media only screen and (max-width: 1100px) {
    
    .filter {
        margin-top: 1px;
    }
    .headerContent div{
        left:40%;
    }
    .subHeaderLine{
        margin-top: 120px;
    }
}

@media only screen and (max-width: 600px) {
    .filter {
        margin-top: 1px;
    }
    .header{
        height: 30vh;
    }
    .headerContent div{
        width: 150px;
        left:35%;
        top:20%;
    }
}