
.detailsHeader {
    height: 70vh;
}

.detailsHeaderImg {
    height: 25vh;
}
.detailsHeaderImg img{
    object-position: 50% 0%;
    height: 100%;
}

.detailsHeaderRight1 {
    height: 25vh;
    width: 100%;
    background-color: #231F20;
}

.detailsFlag {
    text-align: center;
    position: absolute;
    margin: 5%;
    /* left: 38%;
    top: 0%; */
    line-height: 60px;
}
.detailsHeaderRight1Details{
    margin-left:10%;
    padding-top: 30px;
}
.detailsHeaderRight1Driver {
    text-align: center;
    /* position: absolute; */
    margin: 5%;
    /* left: 45%; */
    /* top: -4%; */
}

.detailsHeaderRight1Driver p {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 30px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.detailsHeaderRight1Points {
    text-align: center;
    /* position: absolute; */
    margin-top: -5%;
    /* left: 52%; */
    /* top: 4%; */
}

.detailsHeaderRight1Points p {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 30px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.detailsHeaderRight2 {
    height: 41vh;
    width: 100%;
    background-color: #F7F5F5;
    border-top: 1px solid #231F20;
}
.detailsHeaderRight2Content{
    margin-top: 20px;
}
.tableRight {
    /* position: absolute;
    left: 35%; */
    width: 90%;
    border-spacing: 2px;
    margin-left: 5%;
}

.leftTH {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-transform: uppercase;
    color: #000000;
}

.rightTH {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-align: right;
    text-transform: uppercase;
    color: #000000;
}


.biographyTitle {
    width: 100%;
    margin-top: 50px;
    position: relative;
    height: 100%;
    width: 100%;
    background-color: rgba(217, 217, 217, 0.54);
}

.biographyContent {
    background-color: #BF2A30;
    width: 30vh;
    height: 50px;
    position: absolute;
    left: 22%;
    top:-5%;
    text-align: center;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding-top: 30px;
}
.biographyBody{
    padding: 80px;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-transform: lowercase;
    color: #000000;
}



@media only screen and (max-width: 900px) {
    .headerRight {
        float: left;
        width: 100%;
        object-fit: cover;
    }

    .filter {
        margin-top: 1px;
    }

    .headerLeft p {
        font-size: 1rem;
    }

    .viewsPar {
        width: 40vh;
        margin-left: 13%;
        font-size: 18px;

    }

}


@media only screen and (max-width: 1250px) {
    .driverCardsSection {
        margin-top: 70px;
    }

    .driverCardDriverName p {
        font-size: 35px;
        overflow-wrap: break-word;
    }
    .detailsHeaderImg{
        display: block;
        position: inherit;
    }
    .detailsHeaderImg img{
        height: 400px;
        width: 100%;
        object-position: 50% 0%;
    }

    .detailsHeaderRight1 {
        display: block;
        position: relative;
        height: 20vh;
        width: 100%;
        background-color: #231F20;
    }

    .detailsFlag {
        position: absolute;
        margin: 5%;
        left: 8%;
        top: 15%;
        line-height: 60px;
    }

    .detailsHeaderRight1Details{
        margin-left: 15%;
    }
    .detailsHeaderRight1Driver {
        text-align: center;
        /* position: absolute; */
        margin: 5%;
        /* left: 5%;
        top: 0%; */
        width: 100%;
    }

    .detailsHeaderRight1Driver p {
        font-family: 'League Spartan';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        text-transform: uppercase;
        color: #FFFFFF;
    }

    .detailsHeaderRight1Points {
        text-align: center;
        /* position: absolute; */
        margin-top: -5%;
        /* left: 35%;
        top: 4%; */
    }

    .detailsHeaderRight1Points p {
        font-family: 'League Spartan';
        font-style: normal;
        font-weight: 700;
        font-size: 45px;
        line-height: 30px;
        text-transform: uppercase;
        color: #FFFFFF;
    }
    .detailsHeaderRight2 {
        display: block;
        height: 100%;
        width: 100%;
        background-color: #F7F5F5;
    }
    .detailsHeaderRight2Content{
        margin-top: 20px;
    }
    .tableRight {
        position: absolute;
        left: 5%;
        width: 90%;
        border-spacing: 2px;
    }

    .leftTH {
        font-family: 'League Spartan';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        text-transform: uppercase;
        color: #000000;
    }

    .rightTH {
        font-family: 'League Spartan';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        text-align: right;
        text-transform: uppercase;
        color: #000000;
    }

    .biographyTitle {
        width: 100%;
        margin-top: 700px;
        display: flex;
        height: 100%;
        width: 100%;
        background-color: rgba(217, 217, 217, 0.54);
    }

    .biographyContent {
        background-color: #BF2A30;
        width: 30vh;
        height: 50px;
        position: absolute;
        left: 22%;
        top:-3%;
        text-align: center;
        font-family: 'League Spartan';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        text-transform: uppercase;
        color: #FFFFFF;
        padding-top: 30px;
    }
    .biographyBody{
        padding: 80px;
        font-family: 'League Spartan';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        text-transform: lowercase;
        color: #000000;
    }

}
