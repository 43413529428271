.header {
    height: 230px;
    text-align: center;
    width: 100%;
    display: table;
}

.headerContent {
    display: table-cell;
    vertical-align: middle;
}

.headerContent h3 {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 37px;
    text-align: center;
    text-transform: uppercase;
    color: #BF2A30;
}

.body {
    background-color: #BEBEBE;
    height: 600px;
    position: relative;
}

.cardSection {
    position: absolute;
    height: 700px;
    background-color: white;
    width: 40%;
    top: 20%;
    left: 30%;
    text-align: center;
}

.cardSectionContact {
    height: 80%;
    margin-top: 80px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.cardSectionContact p {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #E3332B;
    text-transform: uppercase;
    margin-bottom: 40px;
}

.submitBtn {
    background-color: #BF2A30 !important;
    border: none!important;
    font-family: 'League Spartan'!important;
    font-style: normal!important;
    font-weight: 400!important;
    font-size: 15px!important;
    line-height: 14px!important;
    display: flex!important;
    align-items: center!important;
    text-align: center!important;
    letter-spacing: -0.015em!important;
    color: #FFFFFF!important;
    height: 50px!important;
    margin-left: 20px!important;
    width: 150px!important;
}
.submitBtn:hover{
    color:#BF2A30!important;
    background-color: white!important;
    border: 2px solid #BF2A30!important;
}
.body2{
    background-color: #F0F0F0;
    height: 500px;
}

@media only screen and (max-width: 800px) {
    .cardSection {
        width: 90%;
        left: 5%;
        height: 800px;
    }
}

@media only screen and (min-width: 801px) and (max-width: 1300px) {
    .cardSection {
        width: 80%;
        left: 10%;
        height: 700px;

    }
}