.main {
    position: relative;
    background-color: #F0F0F0;
    overflow: hidden;
}

.btnList {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 10%;
    justify-content: center;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.btn {
    background: #ED2022;
    color: #000;
    display: block;
    font-size: .6875rem;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 1;
    margin: 0px 10px 10px 0px;
    padding: 0px 10px;
    text-align: center;
    text-decoration: none;
    transition: background .4s ease, color .4s ease;
    cursor: pointer;
    min-width: 200px
}


.btn:hover {
    background-color: rgba(40, 40, 40, 0.7);
    color: white;
}



.countdown {
    position: fixed;
    z-index: 98;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100%;
}


.countdown>span {
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    height: 100%;
    /* transition: all .5s; */
}


.countdown>span::before {
    position: absolute;
    top: 0;
    left: -6px;
    border-color: transparent transparent #fff transparent;
    border-style: solid;
    border-width: 0 0 4px 6px;
    content: '';
    height: 0;
    width: 0;

}







.frameDiv {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}

.frameDiv iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.HomeHeroSection {
    background: radial-gradient(43.05% 51.13% at 63.52% 48.75%, rgba(0, 0, 0, 0) 0.66%, #000000 100%), url(../assets/images/home-hero-img.png);
    ;

    background-repeat: no-repeat;
    background-size: cover;
    height: 80vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'League Spartan';
    font-style: normal;
    text-align: center;
    color: #FFFFFF;
}


.HomeHeroTitle {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 44px;
    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF;
}

.HomeHeroSubTitle {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 22px;
    text-align: center;

    color: #FFFFFF;
    max-width: 558px;
    margin-left: auto;
    margin-right: auto;
}

.WhoSectionBig{
    height: 65vh;
    width: 85%;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
    border-top: 1px solid #544F50;
}

.WhoSection {
    background: url(../assets/images/flag.png) rgba(229, 228, 228, 1);
    height: 50vh;
    width: 80%;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: top;
    background-size: contain;

}

.WhoContent {
    max-width: 365px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 12vw;
}


.WhoTitle {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    color: #BF2A30;
    text-align: left;
}
.WhoTitleAr{

    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 18px;
    text-transform: uppercase;
    color: #BF2A30;
    text-align: right;
}

.WhoSubTitle {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 44px;
    text-align: center;
    text-transform: uppercase;

    color: #544F50;
    text-align: left;
}

.WhoSubTitleAr {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 44px;
    text-transform: uppercase;
    color: #544F50;
    text-align: right;
}

.WhoParag {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: left;
    color: #000000;
}
.WhoParagAr{
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: right;
    color: #000000;
}

.WhoBtn {
    background: #BF2A30;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 12px;
    text-align: center;
    color: #FFFFFF;
    border-width: 0px;
    padding: 10px;
    border-radius: 20px;
    margin-right: 50%;
}

.WhoBtnAr {
    background: #BF2A30;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 12px;
    text-align: center;
    color: #FFFFFF;
    border-width: 0px;
    padding: 10px;
    border-radius: 20px;
    margin-left: 50%;
    margin-top:10px;
}

.latestVideos {
    background: linear-gradient(rgba(1, 1, 1, 0.7), rgba(1, 1, 1, 0.7)),
        url(../assets/images/flagbg.png);
    margin-top: 50px;
    height: 60vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.latestVideosTitle {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 44px;
    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF;

}


.latestVideosBtns {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.latestVideosBtn {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 10px 30px 10px 30px;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    color: #544F50;
    border-width: 0px;
    margin: 20px;
}
.latestVideosBtn:hover{
    cursor: pointer;
}

.watchMoreBtn {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 12px;
    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF;
    background: #BF2A30;
    border-radius: 20px;
    border-width: 0px;
    padding: 15px 30px 15px 30px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
}


.aboutusHero {
    background: #231F20;
    height: 25vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutusHero>h1 {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 33px;
    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF;
}


.aboutusSection {
    background: url(../assets/images/abouus-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 55vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'League Spartan';
    font-style: normal;
    text-align: center;

}



.aboutusSection>h1 {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;


    text-align: center;
    text-transform: uppercase;

    color: #BF2A30;
}


.aboutusSection>h2 {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 18px;
    text-align: center;

    color: #3F3F3F;
    max-width: 500px;
}

@media only screen and (max-width: 800px) {

    .latestVideosBtns {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .latestVideosBtn{
        width: 50%;
    }
    .HomeHeroSection div p{
        line-height: 35px;
    }
    .WhoTitle{
        font-size: 20px;
        line-height: 0px;
    }
    .WhoSubTitle{
        font-size: 25px;
        line-height: 1px;
    }
    .latestVideosTitle{
        font-size: 38px;
        line-height: 1px;
    }
    .WhoContent{
        margin-left: 5px;
        margin-right: 15px;
    }
}
@media only screen and (max-width: 600px) {
    .latestVideosTitle{
        font-size: 32px;
        line-height: 1px;
    }

    .latestVideosBtn {
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        text-transform: uppercase;
        color: #544F50;
        border-width: 0px;
        margin: 8px;
    }
    .aboutusSection{
        background-position: center;
    }
    .aboutusSection div{
        margin-left:5px;
        margin-right:5px;
    }
}