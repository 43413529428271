@import url(//db.onlinewebfonts.com/c/19a516eaa6ae19744976e313ea5896f4?family=29LT+Bukra+Variable);

@font-face {
  font-family: "29LT Bukra Variable";
  src: url("//db.onlinewebfonts.com/t/19a516eaa6ae19744976e313ea5896f4.eot");
  src: url("//db.onlinewebfonts.com/t/19a516eaa6ae19744976e313ea5896f4.eot?#iefix") format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/19a516eaa6ae19744976e313ea5896f4.woff2") format("woff2"),
    url("//db.onlinewebfonts.com/t/19a516eaa6ae19744976e313ea5896f4.woff") format("woff"),
    url("//db.onlinewebfonts.com/t/19a516eaa6ae19744976e313ea5896f4.ttf") format("truetype"),
    url("//db.onlinewebfonts.com/t/19a516eaa6ae19744976e313ea5896f4.svg#29LT Bukra Variable") format("svg");
}

.headerNavArabic {
  /* position: absolute;
  width:  80% ;
background-color: rgba(40,40,40,0.7);
height: 50px;
z-index: 1; */
  font-family: '29LT Bukra Variable';
}



.header {
  position: absolute;
  width: 80%;
  height: 50px;
  z-index: 1;
}

.menuBtn {
  text-decoration: none;
  color: white;
  width: 100%;
  z-index: 1;
  padding: 14px 16px;
  display: block;
}



.menuLinks {
  display: grid;
  padding: 10px 100px 20px 100px;
  transition: all 2s;
}


.hidenMenuLinks {
  display: none;
  transition: all 2s;
}


.navMenu {
  overflow: hidden;
  background-color: #333;
  position: relative;

}

.navMenu #myLinks {
  display: none;
}



.menuIcon {
  font-size: 32px;
  padding: 10px, ;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}

.menuBtn:hover {
  color: #BF2A30;
}

.active {
  background-color: #04AA6D;
  color: white;
}




.nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: 20px;
  z-index: 1;
  width: 100%;
}

.nav a {
  z-index: 1;
  text-decoration: none;
}

.navItem {
  color: white;
  margin: 10px;
  cursor: pointer;
  min-width: 80px;
  text-align: center;
  font-family: 'League Spartan', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
}

.navItem:hover {
  opacity: .7;
}



.logo {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.logo2 {
  position: absolute;
  top: 10;
  right: 0;
  z-index: 1;
}

.link,
.active_link {
  /* font-family: sans-serif; */
  font-family: 'League Spartan', sans-serif;

  text-decoration: none;
  color: #BF2A30;

  position: relative;
  /* margin: 10px 0; */
  display: inline-block;
  padding: 12px 10px;
}

.active_link {
  background-color: #BF2A30;
}

.active_link>h1 {
  font-family: 'League Spartan';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;
  background-color: #BF2A30;
  color: white
}

.link::after,
.active_link::after {
  content: "";
  background: white;
  background-color: #BF2A30;
  height: 2px;
  position: absolute;
  bottom: 0;
  transition: .16s all 0.025s;
}

.link::after,
.active_link::after {
  left: 100%;
  right: 0;

}

.active_link~.link::after {
  left: 0;
  right: 100%;


}

.active_link::after {
  left: 0;
  right: 0;

}


@media only screen and (max-width: 800px) {
  .nav {
    width: 100%;
  }

  .navItem {
    margin: 5px;
    min-width: 20px;
    font-size: 12px;
  }
}