.imageSlider {
    height: 500px;
    position: relative;
    margin-top: 20px;
    width: 90%;
    margin-right: 5%;
    margin-left: 5%;
    margin-bottom: 150px;
}

.slider {
    width: 100%;
    height: 600px;
    text-align: center;
    background-color: white;
}

.sliderContent {
    text-align: center;
    width: 100%;
    vertical-align: middle;
    position: relative;
    margin-top:20px;
    height: 500px;
}

.slider img {
    object-fit: cover;
    background-color: white;
    margin:0 auto;
    display: block;
}

.ArrowForward {
    position: absolute;
    top: 45%;
    right: -2%;
    background-color: #BF2A30;
    border: none;
    border-radius: 45%;
    color: white;
}

.ArrowBack {
    position: absolute;
    top: 45%;
    left: -2%;
    background-color: #BF2A30;
    border: none;
    border-radius: 45%;
    color: white;
}

.ArrowForward:hover {
    cursor: pointer;
}

.ArrowBack:hover {
    cursor: pointer;
}

.imageContainer {
    width: 100%;
    margin-right: 5%;
    margin-left: 5%;
    margin-bottom: 20px;
}

.imageContainer img {
    margin: 0 auto;
    display: block;
}

.bodySection {
    width: 100%;
    margin-right: 5%;
    margin-left: 5%;
}

.bodySection h3 {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 48px;
    color: #000000;
    text-transform: uppercase;
}

.bodySection p {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
}

.colorCaseSelected {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    margin-top: 30px;
    border: 2px solid #BF2A30;
}

.colorCase {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    margin-top: 30px;
}

.colorCase:hover {
    cursor: pointer;
}

.colorCaseSelected:hover {
    cursor: pointer;
}

.sizeCase {
    width: 90px;
    height: 50px;
    margin-top: 40px;
    background-color: white;
    text-align: center;
    border: 1px solid #000000;
}

.sizeCase div {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 30px;
    text-align: center;
    color: #000000;
    padding-top: 12px;
}

.sizeCaseSelected {
    width: 90px;
    height: 50px;
    margin-top: 40px;
    background-color: #BF2A30;
    border: 1px solid #9E030A;
    text-align: center;
}

.sizeCaseSelected div {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    padding-top: 12px;
}

.sizeCase:hover {
    cursor: pointer;
    background-color: #BF2A30;
    border: 1px solid #9E030A;
}

.sizeCase div:hover {
    color: #fff;
}

.sizeCaseSelected:hover {
    cursor: pointer;
}

.actions {
    margin-bottom: 50px;
}

.counter {
    display: flex;
    height: 70px;
    width: 30%;
    border: 1px solid #BF2A30;
}

.add {
    height: 70px;
    width: 60%;
}

.counter>button {
    width: calc(100%/3);
    background-color: #BF2A30;
    border: 0px;
    color: white;
    font-size: 1.4rem;
    font-weight: 700;
    cursor: pointer;

}

.counter>p {
    width: calc(100%/3);
    text-align: center;
}

.add>button {
    width: 100%;
    height: 100%;
    background-color: #544F50;
    border: 1px solid #544F50;
    color: white;
    font-size: 1.4rem;
    font-weight: 700;
    cursor: pointer;
}


.counter>button:hover,
.add>button:hover {
    background-color: #ab181b;
}

@media only screen and (max-width: 1100px) {
    .slider img {
        width: 400px;
        margin-top:100px;
    }
}