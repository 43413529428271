.header {
    height: 50vh;
    width: 100vw;
}

.body {
    margin-top: 3%;
    margin-right: 5%;
    margin-left: 5%;
    margin-bottom: 5%;
    width: 90%;
}

.subHeaderLine {
    height: 1px;
    width: 100%;
    background-color: black;
    border: 1px solid black;
    margin-top: 2%;
    position: relative;
}

.subHeaderLineContent {
    background-color: #BF2A30;
    width: 15vh;
    height: 25px;
    position: absolute;
    margin-top: -12px;
    right: 10%;
}
.videoSlider {
    height: 300px;
    position: relative;
    margin-top: 80px;
    width: 90%;
    margin-right: 5%;
    margin-left: 5%;
    margin-bottom: 150px;
}

.slider {
    width: 100%;
    height: 300px;
    text-align: center;
}

.sliderContent {
    text-align: center;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    position: relative;
}

.slider img {
    object-fit: cover;
    background-color: white;
    margin:0 auto;
    display: block;
}

.ArrowForward {
    position: absolute;
    top: 45%;
    right: -5%;
    background-color: #BF2A30;
    border: none;
    border-radius: 45%;
    color: white;
}

.ArrowBack {
    position: absolute;
    top: 45%;
    left: -5%;
    background-color: #BF2A30;
    border: none;
    border-radius: 45%;
    color: white;
}

.ArrowForward:hover {
    cursor: pointer;
}

.ArrowBack:hover {
    cursor: pointer;
}

@media only screen and (max-width: 800px) {
    .header{
        background-position: center;
    }
    .body video{
        height: 300px;
    }
}