.header {
    width: 100%;
    background-color: #FFFFFF;
    position:relative;
}

.headerLeft {
    float: left;
    width: 60%;
    position: absolute;
}

.headerLeft p {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 30px;
    color: #000000;
    margin-top: 15%;
    margin-left: 15%;
    margin-right: 15%;
}

.headerLeftLine {
    height: 2px;
    background-color: #BF2A30;
    border: 2px solid #BF2A30;
    width: 35%;
    margin-left: 15%;
}

.headerRight {
    height: 50vh;
    width: 60%;
    float: right;
}

.subHeaderMenu {
    background-color: #544F50;
    color: white;
}

.subHeaderLine {
    height: 1px;
    width: 85%;
    margin-top: 3%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 1%;
    position: relative;
}

.filter {
    width: 200px;
    height: 25px;
    position: absolute;
    margin-top: -25px;
    right: 0%;
}

.driverCardNbr {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 60px;
    text-transform: uppercase;
    color: #000000;
    margin: 5%;
}

.driverCardFlag {
    position: absolute;
    margin: 5%;
    right: 2%;
    top: 0%;
    line-height: 60px;
}

.driverCardsSection {
    display: flex;
    margin-top: 5%;
    margin-right: 5%;
    margin-left: 5%;
    width: 100%;
}

.driverCardDriverName {
    text-align: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
}

.driverCardDriverName p {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 80px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-top: auto;
    overflow-wrap: break-word;
    width: 50%;
    margin: auto;
}


.viewsPar {
    height: 60px;
    width: 40vw;
    border-bottom: 2px solid black;
    margin-left: 30%;
    position: relative;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;
}

.viewsPar p {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 22px;
    color: #BF2A30;
}

.loadingBtn {
    height: 60px;
    width: 40vw;
    margin-left: 30%;
    position: relative;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;
    background: #FFFFFF;
    color: #544F50;
    border: 1px solid #BF2A30;
}

.loadingBtnContainer p {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 22px;
    text-transform: uppercase;
}

.loadingBtn:hover {
    cursor: pointer;
    background-color: #BF2A30;
    color: #FFFFFF;
}

.detailsHeader {
    height: 70vh;
}

.detailsHeaderImg {
    position: absolute;
}

.detailsHeaderImg img {
    object-fit: cover;
    object-position: 50% 50%;
}

.detailsHeaderRight1 {
    height: 25vh;
    width: 100%;
    background-color: #231F20;
}

.detailsFlag {
    text-align: center;
    position: absolute;
    margin: 5%;
    left: 38%;
    top: 0%;
    line-height: 60px;
}

.detailsHeaderRight1Details {
    margin-left: 40%;
    padding-top: 30px;
}

.detailsHeaderRight1Driver {
    text-align: center;
    /* position: absolute; */
    margin: 5%;
    /* left: 45%; */
    /* top: -4%; */
}

.detailsHeaderRight1Driver p {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 30px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.detailsHeaderRight1Points {
    text-align: center;
    /* position: absolute; */
    margin-top: -5%;
    /* left: 52%; */
    /* top: 4%; */
}

.detailsHeaderRight1Points p {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 30px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.detailsHeaderRight2 {
    height: 41vh;
    width: 100%;
    background-color: #F7F5F5;
    border-top: 1px solid #231F20;
}

.detailsHeaderRight2Content {
    margin-top: 20px;
}

.tableRight {
    position: absolute;
    left: 35%;
    width: 60%;
    border-spacing: 2px;
}

.leftTH {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-transform: uppercase;
    color: #000000;
}

.rightTH {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-align: right;
    text-transform: uppercase;
    color: #000000;
}


.biographyTitle {
    width: 100%;
    margin-top: 50px;
    position: relative;
    height: 60vh;
    width: 100%;
    background-color: rgba(217, 217, 217, 0.54);
}

.biographyContent {
    background-color: #BF2A30;
    width: 30vh;
    height: 50px;
    position: absolute;
    left: 22%;
    top: -5%;
    text-align: center;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding-top: 30px;
}

.biographyBody {
    padding: 80px;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-transform: lowercase;
    color: #000000;
}

.shopButton {
    background: #D9D9D9 !important;
    height: 25vh!important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 20% !important;
    background-color: #BF2A30 !important;
    width: 100% !important;
    font-family: 'League Spartan' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 40px !important;
    text-align: center !important;
    text-transform: uppercase !important;
    color: #FFFFFF !important;
}

@media only screen and (max-width: 900px) {
    .headerRight {
        float: left;
        width: 100%;
        object-fit: cover;
    }

    .filter {
        margin-top: 1px;
    }

    .headerLeft p {
        font-size: 1rem;
    }

    .viewsPar {
        width: 40vh;
        margin-left: 13%;
        font-size: 18px;

    }

}


@media only screen and (max-width: 1250px) {
    .driverCardsSection {
        margin-top: 70px;
    }

    .driverCardDriverName p {
        font-size: 35px;
        overflow-wrap: break-word;
    }

    .detailsHeaderImg {
        display: block;
        position: inherit;
    }

    .detailsHeaderImg img {
        height: 400px;
        width: 100%;
        object-position: 50% 0%;
    }

    .detailsHeaderRight1 {
        display: block;
        position: relative;
        height: 20vh;
        width: 100%;
        background-color: #231F20;
    }

    .detailsFlag {
        position: absolute;
        margin: 5%;
        left: 8%;
        top: 15%;
        line-height: 60px;
    }

    .detailsHeaderRight1Details {
        margin-left: 15%;
    }

    .detailsHeaderRight1Driver {
        text-align: center;
        /* position: absolute; */
        margin: 5%;
        /* left: 5%;
        top: 0%; */
        width: 100%;
    }

    .detailsHeaderRight1Driver p {
        font-family: 'League Spartan';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        text-transform: uppercase;
        color: #FFFFFF;
    }

    .detailsHeaderRight1Points {
        text-align: center;
        /* position: absolute; */
        margin-top: -5%;
        /* left: 35%;
        top: 4%; */
    }

    .detailsHeaderRight1Points p {
        font-family: 'League Spartan';
        font-style: normal;
        font-weight: 700;
        font-size: 45px;
        line-height: 30px;
        text-transform: uppercase;
        color: #FFFFFF;
    }

    .detailsHeaderRight2 {
        display: block;
        height: 100%;
        width: 100%;
        background-color: #F7F5F5;
    }

    .detailsHeaderRight2Content {
        margin-top: 20px;
    }

    .tableRight {
        position: absolute;
        left: 5%;
        width: 90%;
        border-spacing: 2px;
    }

    .leftTH {
        font-family: 'League Spartan';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        text-transform: uppercase;
        color: #000000;
    }

    .rightTH {
        font-family: 'League Spartan';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        text-align: right;
        text-transform: uppercase;
        color: #000000;
    }

    .biographyTitle {
        width: 100%;
        margin-top: 700px;
        display: flex;
        height: 100%;
        width: 100%;
        background-color: rgba(217, 217, 217, 0.54);
    }

    .biographyContent {
        background-color: #BF2A30;
        width: 30vh;
        height: 50px;
        position: absolute;
        left: 22%;
        top: -3%;
        text-align: center;
        font-family: 'League Spartan';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        text-transform: uppercase;
        color: #FFFFFF;
        padding-top: 30px;
    }

    .biographyBody {
        padding: 80px;
        font-family: 'League Spartan';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        text-transform: lowercase;
        color: #000000;
    }

}

@media only screen and (max-width: 800px) {
    .detailsHeaderRight1Points {
        text-align: center;
        margin-top: -5%;
    }

    .detailsHeaderRight1Points p {
        font-family: 'League Spartan';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 30px;
        text-transform: uppercase;
        color: #FFFFFF;
    }
}