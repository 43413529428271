.aboutusValue {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}


.aboutusValue>h1 {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    background: #231F20;
    margin: 0px;
    padding: 30px 60px 30px 60px;
    border-bottom-left-radius: 70px;
    border-bottom-right-radius: 70px;
}

.card {
    min-height: 300px;
    text-align: center;
}

.card img {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    border: none;
}

.textSection p {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: #000000;
}
.textSection span{
    display: block;
}

.EmployeeCardSection{
    /* border:1px solid black */
}