.orderBtn{
    background-color: #ED2022;
    color: #fff;
    border: 0;
    display: inline-block;
    text-align: center;
    transition: color .3s,background-color .3s,opacity .3s;
    text-transform: uppercase;
    cursor: pointer;
    white-space: nowrap;
    /* font-family: 'noto-sans'; */
    font-family: 'League Spartan',sans-serif;

    font-size: 1rem;
    padding: 0rem 2rem;
    font-weight: 700;
    border-radius: 0;
    margin: 30px auto ;
}

.orderBtn:disabled ,.orderBtn:hover:disabled {
    background-color: grey; 
    cursor:default;
}


.orderBtn:hover{
    background-color: #7b1214;
}

.messageBox{
    width: 100%;
    border: 2px solid #ED2022;
    min-height:50px;
    text-align: center;
    padding-top:40px;
    padding-bottom:20px;
    font-size: 25px;
    font-family: 'League Spartan';
}
.homeButton{
    width: 100px !important;
    background-color: #544F50 !important;
    font-size: 18px !important;
    font-family: 'League Spartan' !important;
    color:#fff !important;
}
.homeButton:hover{
    background-color: #ED2022 !important;
}