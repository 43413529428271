
.footer {
    width: 100%;
    margin: auto;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    background: #111;
    height: 230px;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-align: center;
    bottom: 0;
    left:0;
    right: 0;
}

.footerContent {
    width: 90%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-align: center;
}

.footerVideo {
    height: 90%;
    margin: auto;
    width: 50%;
    color: white;
    text-decoration: none;
    /* background-color: aliceblue; */

}

.footerVideo video {
    width: 100%;
    height: 100%;

}

.footerIcons a {
    text-decoration: none;
    color: white;
    margin: 10px;
}

ul {
    padding: 0;
    list-style: none;
    text-align: center;
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 0;
}

li {
    padding: 0 10px;
}

ul a {
    color: inherit;
    text-decoration: none;
    opacity: 0.8;
}

ul a:hover {
    opacity: 1;
}
