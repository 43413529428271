body {
  margin: 0px;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'League Spartan',sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: white;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', */
  /* monospace; */

  font-family: 'League Spartan',sans-serif;

}



h1{
  /* font-size: 6rem;
  line-height: 5.7rem; */
  font-size: 6vw;
  line-height: 5.7vw;
  /* font-family: "Space Grotesk",sans-serif; */
  font-family: 'League Spartan',sans-serif;

}


h2{
  /* font-size: 3rem;
    line-height: 3.4rem; */
    font-size: 3vw;
    line-height: 3.4vw;
    font-weight: 300;
    font-family: "Space Grotesk",sans-serif;
}

p{
  font-size: 1.125rem;
  line-height: 1.4625rem;
  /* font-size: 1.125vw;
  line-height: 1.4625vw; */
  /* font-family: "Space Grotesk",sans-serif; */
  font-family: 'League Spartan',sans-serif;

}





.feedback-button{
  border: none;
  position: fixed !important;
  margin-top: 0px;

  background-color: transparent !important;
  /* font-family: "Open Sans",sans-serif; */
  font-family: 'League Spartan',sans-serif;

  cursor: pointer;
  padding: 0px !important;
  top: 50%;
  right: -45px;
  z-index: 99999990;
  height: 35px !important;
  min-height: 35px !important;
  max-height: 35px !important;
  width: 125px !important;
  max-width: 125px !important;
  min-width: 125px !important;
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);

}

.feedback-button>div{
  color: #ffffff;
    font-size: 14px;
    line-height: 35px;
    text-align: center;
    font-weight: normal !important;
    background-color: #ED2022;
}



.container{
	max-width: 1170px;
	margin:auto;
}
.row{
	display: flex;
	flex-wrap: wrap;
  margin-left: 80px;
}
ul{
	list-style: none;
}
.footer{
	/* background-color: #24262b; */
  background-color: #BF2A30;
  /* padding: 20px 20px; */
  border-top: 1px solid #dedede;
  min-height: 150px;
  height: 100%;
  
}

.footer > .row{
  display: flex;
  /* justify-content: space-between; */
}
.footer-col{
   /* width: 20%; */
   padding: 0px 15px;
}
.footer-col-container{
  margin-left:250px;
}
.footer-col2{
  margin-left:300px;
}
.footer-col h4{
	font-size: 18px;
	/* color: #ffffff; */
  color: rgba(255, 255, 255, 1)  ;
	text-transform: capitalize;
	margin-bottom: 35px;
	font-weight: 500;
	position: relative;


  font-family: 'League Spartan';
font-style: normal;
font-weight: 800;
font-size: 16px;
line-height: 15px;

color: #FFFFFF;
}
/* .footer-col h4::before{
	content: '';
	position: absolute;
	left:0;
	bottom: -10px;
	background-color: #ED2022;
	height: 2px;
	box-sizing: border-box;
	width: 50px;
} */
.footer-col ul li:not(:last-child){
	margin-bottom: 10px;
}
.footer-col ul li a{
	/* font-size: 11px; */
	text-transform: capitalize;
	margin: 0px 0px 10px;
  color: rgba(255, 255, 255, 1)  ;
	text-decoration: none;
	font-weight: 300;
 
	display: block;
	transition: all 0.3s ease;


  font-family: 'League Spartan';
font-style: normal;
font-weight: 600;
font-size: 13px;
line-height: 12px;

color: #FFFFFF;


}
.footer-col ul li a:hover{
	/* color: #ffffff; */
  color: #24262b;
	padding-left: 8px;
}

.footer-row{
display: flex;
flex-direction: row;
width: 100%;
justify-content: flex-end;
}
.footer-row .social-links{
   
}

.footer-row .social-links a{
	display: inline-block;
	height: 40px;
	width: 40px;
	/* background-color: #24262b; */
	margin:0px 10px 10px 0px;
	text-align: right;
	line-height: 40px;
	border-radius: 50%;
 
  color: rgba(40,40,40);
	transition: all 0.5s ease;
}
.footer-row .social-links a:hover{
	/* color: #24262b; */
 
  /* background-color: rgba(40,40,40); */
}
.footer-col-right{
  margin-left: 250px;
}
.footer-content ul li a{
  text-transform: uppercase;
  font-family: 'League Spartan';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 12px;
  color: #FFFFFF;
  text-align: left !important;
}


/*responsive*/
@media(max-width: 767px){
  .footer-col{
    width: 50%;
    margin-bottom: 30px;
}
}
@media(max-width: 574px){
  .footer-col{
    width: 100%;
}
}



@media(max-width: 800px){
 .footer-col-container{
  margin-left: 0px;
 }
 li a{
  text-align: center !important;
 }
 .footer-col2{
  margin-left: 0px;
 }
 .footer-col-right{
  margin-left: 0px;
 }
 .footer-part2{
   margin-left: 50px;
 }
}

@media only screen and (max-width: 1100px) {
  .footer-col-container{
    margin-left: 10px;
  }
  .footer-col2{
    margin-left: 50px;
  }
}

@media only screen and (min-width: 1101px)and (max-width: 1500px) {
  .footer-col-container{
    margin-left: 10px;
  }
  .footer-col2{
    margin-left: 10px;
  }
}



.fotter-bottom{
  text-align: center;
}
 .fotter-bottom>h1{
  font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
/* identical to box height */

text-transform: uppercase;

color: #3F3F3F;
 }