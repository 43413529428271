.imageSection{
    text-align: center;
}
.bodySection{
    height: 80px;
    background-color: #F0F0F0;
}
.bodySection div{
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    padding-left:20px;
    padding-top:10px;
}

.buttomSection{
    height: 40px;
    background-color: #544F50;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    text-align: center;
    text-transform: uppercase;
}
.buttomSection div{
    padding: 10px;
}
