body {
    background-color: '#F0F0F0';
}

.header {
    height: 50vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    position:relative;

}

.header p {
    position: absolute;
    left: 10%;
    right: 0%;
    bottom: 0%;
    top: 43%;
    font-weight: 700;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 5vw;
    line-height: 60px;
}

.subHeaderMenu {
    background-color: #544F50;
    color: white;
    width:'100%'
}

.ads {
    background: #D9D9D9;
    height: 25vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    margin-right: 5%;
    margin-left: 5%;
    width: 100%;
}

.ads>h1 {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 33px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

.subHeaderLine {
    height: 1px;
    width: 100vw;
    background-color: black;
    border: 1px solid black;
    margin: 5%;
    position: relative;
}

.subHeaderLineContent {
    background-color: #BF2A30;
    width: 15vh;
    height: 25px;
    position: absolute;
    margin-top: -12px;
    right: 10%;
}

.newsCard {
    background: #D9D9D9;
    height: 65vh;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    margin-top: 5%;
    margin-right: 5%;
    margin-left: 5%;
    width: 100%;
    margin-bottom: 10px;
}

.imageContainer {
    width: 40%;
    height: 65vh;
    float: left;
}

.imageContainer img {
    object-fit: cover;
}

.rightContainer {
    height: 60vh;
    width: 59%;
    float: right;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: table;
}
.rightContainerTable{
    display: table-cell;
    vertical-align: middle;
}

.rightContainer h3 {
    /* margin-top: 25%; */
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 29px;
    text-transform: uppercase;
    color: #BF2A30;
}

.rightContainer p {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 18px;
    color: #000000;
}

.rightContainerPar {
    width: 80%;
    margin: auto;
    /* word-break: break-all; */
    word-wrap: break-word;
}

.rightReadMoreBtn {
    background-color: #000000;
    width: 160px;
    height: 30px;
    border-radius: 25px;
    margin-left: auto;
    text-align: center;
    margin-top:50px;
    left: 33vh;
}

.rightReadMoreBtn p {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 12px;
    color: #FFFFFF;
    text-transform: uppercase;
    padding: 10px;
}
.rightReadMoreBtn:hover{
    cursor: pointer;
    background-color: #BF2A30;
}

.viewsPar {
    height: 60px;
    width: 40vw;
    border-bottom: 2px solid black;
    margin-left: 30%;
    position: relative;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;
}

.viewsPar p {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 22px;
    color: #BF2A30;
}



.loadingBtn {
    height: 60px;
    width: 40vw;
    margin-left: 30%;
    position: relative;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;
    background: #FFFFFF;
    color: #544F50;
    border: 1px solid #BF2A30;
}

.loadingBtnContainer p {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 22px;
    text-transform: uppercase;
}

.loadingBtn:hover {
    cursor: pointer;
    background-color: #BF2A30;
    color: #FFFFFF;
}


@media only screen and (max-width: 600px) {
    .header {
        height: 35vh;
    }

    .header p {
        position: absolute;
        left: 10%;
        right: 0%;
        bottom: 10%;
        top: 20%;
        font-weight: 700;
        font-family: 'League Spartan';
        font-style: normal;
        font-weight: 700;
        font-size: 7vw;
        line-height: 60px;
    }

    .viewsPar {
        width: 40vh;
        margin-left: 12%;
    }
}


@media only screen and (max-width: 900px) {
    .imageContainer {
        height: 40%;
        width: 100%;
    }

    .imageContainer img {
        object-fit: cover;
    }

    .newsCard {
        height: 100vh;
    }

    .rightContainer {
        /* display: inline-block; */
        float: none;
        width: 100%;
        text-align: center;
        justify-content: center;
    }
    .rightReadMoreBtn{
        margin-right: auto;
    }
    .viewsPar p {
        font-size: 18px;
    }
}