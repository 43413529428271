.aboutusValue {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}


.aboutusValue>h1 {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    /* or 150% */

    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF;
    background: #231F20;
    margin: 0px;
    padding: 30px 60px 30px 60px;
    border-bottom-left-radius: 70px;
    border-bottom-right-radius: 70px;
}



.card {
    background-color: #FFFFFF;
    margin: 20px;
    max-width: 254px;
    min-height: 254px;
    position: relative;

}

.card>img {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    top: -20%;
}

.card>h1 {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    padding: 10px;
    color: #BF2A30;
    margin-top: 50px;
}


.card>h2 {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    padding: 10px;

    text-align: center;

    color: #000000;
    margin-bottom: 50px;
}

.bigCard {
    min-height: 392px;
    position: absolute;
    bottom: 1px;
    width: 254px;
    background-color: #BF2A30;
    text-align: center;
}

.bigCard p {
    padding: 15px;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

@media only screen and (max-width: 900px) {
    .bigCard {
        display:none
    }
}